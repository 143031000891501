import { Component, HostListener } from '@angular/core';
import { LogoTextComponent } from 'src/app/core/components/logo-text/logo-text.component';
import { CarouselPlansComponent } from 'src/app/shared/components/carousel-plans/carousel-plans.component';
import { CarouselItem } from 'src/app/shared/components/carousel-plans/carousel-plans.model';
import { ActivatedRoute, Router } from '@angular/router';
import { OperationType } from '../finalizar-pagamento/finalizar-pagamento.component';
import { PlanService } from 'src/app/core/services/plan.service';
import { BenefitsModel, MemberPlanDetailModel, PeriodicitiModel, Plan } from 'src/app/shared/models/planModel';
import { CommonService } from 'src/app/core/services/common.service';
import { CustomCurrencyPipe } from 'src/app/shared/pipe/custom-currency.pipe';
import { MemberService } from 'src/app/core/services/member.service';
import { UserService } from 'src/app/core/services/user.service';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { CommonModule, Location } from '@angular/common';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';
import { StyleContentPlan } from 'src/app/shared/models/styleContent';
import { Page } from 'src/app/shared/models/Page';
import { AppModalComponent } from 'src/app/shared/components/app-modal/app-modal.component';
import { UserModel } from 'src/app/shared/models/userModel';
import { PaymentInvoiceModalData } from 'src/app/shared/components/payment-invoice-modal/payment-invoice-modal.component';
import { PaymentType } from 'src/app/shared/models/paymentData';
import { ModalService } from 'src/app/core/services/modal.service';
import { PaymentDataService } from 'src/app/core/services/payment-data.service';
import { PaymentModalComponent } from 'src/app/shared/components/payment-modal/payment-modal.component';
import { PlanPaymentsService } from 'src/app/core/services/plan-payments.service';
import { PlanPaymentUpgradeModel } from 'src/app/shared/models/planPaymentsModel';

@Component({
  selector: 'app-upgrade-plano',
  standalone: true,
  imports: [
    CarouselPlansComponent,
    PaymentModalComponent,
    LogoTextComponent,
    AppButtonComponent,
    CommonModule,
    AppModalComponent,
  ],
  templateUrl: './upgrade-plano.component.html',
  styleUrl: './upgrade-plano.component.scss',
})
export class UpgradePlanoComponent {
  items: CarouselItem[] = [];
  isMobile = window.innerWidth < 768;
  currencyPipe = new CustomCurrencyPipe();
  buttonOptionsCard: any;
  //
  notRenwed: boolean;
  canChekPix = true;
  modalId = 'payment-modal';
  user: UserModel;
  PaymentType = PaymentType;
  paymentType: PaymentType;
  paymentInvoiceModalData: PaymentInvoiceModalData;
  orderModalId = 'orderModal';
  orderModalBodyMessage =
    'Você já possui um pedido de plano em andamento. Deseja continuar com o pedido ou cancelar?';
  //
  buttonOptionsCardSpecial: any;

  buttonBackOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px',
  };

  memberId: string;
  styles: { [key: string]: string };
  selectPlan = false;
  renewPlan = false;
  showPlans = false;
  planDetail: any;

  operation: OperationType = this.paymentDataService.getOperationType();

  constructor(
    private router: Router,
    private planService: PlanService,
    private commonService: CommonService,
    private memberService: MemberService,
    private userService: UserService,
    private location: Location,
    private activateRoute: ActivatedRoute,
    private alertService: AlertService,
    private modalService: ModalService,
    private paymentDataService: PaymentDataService,
    private planPaymentsService: PlanPaymentsService,

  ) {
    this.user = this.userService.currentUserValue as UserModel;
    if (this.activateRoute.snapshot.url[0].path === 'selecionar-plano') {
      this.selectPlan = true;
    }

    if (this.activateRoute.snapshot.url[0].path === 'renovar-plano') {
      this.renewPlan = true;
    }

    this.getPlanConfig();

    this.userService.currentUser.subscribe((user: any) => {
      this.memberId = user?.id || this.memberService.getMemberId();
      if (!this.memberId) {
        this.router.navigate(['/cadastro-socio']);
      }
    });
  }
  //
  onModalConfirm() {
    console.log('Method not implemented.');
  }

  ngOnInit(): void {
    this.notRenwed = this.user?.memberSituation?.id === 'a8c3839b-5fb1-4e99-8869-9e30bfee4f7e'

    if (this.notRenwed) {//Se for um não renovado , seta o tipo de operação para renovação
      this.paymentDataService.setOperationType(OperationType.RENEW);
      this.operation = OperationType.RENEW;
    }

    if (this.user?.cpf) {
      this.getMemberPublicInfo();
    } else {
      this.getAllPlans();
    }
  }

  getMemberPublicInfo() {
    this.activateRoute.queryParams.subscribe(params => {
      const newAdesion = params['newAdesion'] === 'false' ? false : true;
  
      this.memberService.getMemberPublicInfo(this.user.cpf).subscribe((res) => {
        this.user = res;
        if (this.user?.memberPlans?.length == 0 ||
          (this.memberService.isMemberSituationInEnum(this.user?.memberSituation?.id as string))) {
          
          if (this.user?.orders?.length == 0) {
            if (newAdesion === false) {
              this.getPlanbyId();
            } else {
              this.getAllPlans();
            }
          } else {
            if (!this.checkExpirationQRCode()) {
              this.showOrderModal();
              this.getAllPlans();
            } else {
              this.alertService.showAlert(
                AlertType.INFO,
                'Você ainda não possui plano ativo, selecione um plano para continuar.'
              );
              this.getAllPlans();
            }
          }
        } else {
          this.getPlanbyId();
        }
      });
    });
  }

  getOperationTypeLabel() {
    switch (this.operation) {
      case OperationType.UPGRADE:
      case OperationType.UPGRADE_RECURRENCE:
        return 'o upgrade';
      case OperationType.RENEW:
        return 'a renovação';
      case OperationType.EARLY_RENEW:
        return 'a renovação antecipada';
      default:
        return 'a adesão';
    }
  }

  getNoPlansMessage(): string {
    switch (this.operation) {
      case OperationType.UPGRADE:
      case OperationType.UPGRADE_RECURRENCE:
        return 'Seu Plano não possui possíveis Upgrades';
      case OperationType.RENEW:
        return 'Seu Plano não possui possíveis renovações';
      case OperationType.EARLY_RENEW:
        return 'Seu Plano não possui possíveis renovações antecipadas';
      default:
        return 'Não existem planos disponíveis para adesão';
    }
  }

  showOrderModal() {
    this.modalService.open(this.orderModalId);
  }

  checkExpirationQRCode(): boolean {
    if (this.user?.orders && this.user.orders.length > 0) {
      const createdAt = new Date(this.user?.orders[this.user?.orders?.length - 1].createdAt)
      const now = new Date();

      // Diferença em milissegundos entre a data atual e a data de criação
      const differenceInMs = now.getTime() - createdAt.getTime();

      // Converter 58 minutos para milissegundos
      const timeToCompare = (58 * 60 * 1000); // 58 minutos

      // Comparar a diferença
      if (differenceInMs >= timeToCompare) {
        console.log("A diferença é maior ou igual a 58 minutos.");
        return true
      } else {
        console.log("A diferença é menor que 58 minutos.");
        return false
      }
    }
    return true;
  }

  onOrderModalConfirm(event: any): void {
    if (event) {
      if (this.user?.orders && this.user.orders.length > 0) {
        this.paymentInvoiceModalData = {
          invoiceNumber: '',
          invoiceCode: this.user.orders[this.user.orders.length - 1].qrCodeData,
          invoiceCodeImg: this.user.orders[this.user.orders.length - 1].qrCodeImageUrl,
        };
        if (this.user.orders[0].qrCodeData) {
          this.paymentType = PaymentType.PIX;
        }
        this.checkPixPayment(this.user.orders[0].id);
        this.modalService.open(this.modalId);
      }
    }
  }

  checkPixPayment(orderId: any): void {
    this.paymentDataService.getPaymentOrder(orderId).subscribe((data) => {
      if (data.status != 'paid') {
        if (this.canChekPix) {
          setTimeout(() => {
            this.checkPixPayment(orderId);
          }, 3000);
        }
      } else {
        this.paymentDataService.setOperationType(this.operation || OperationType.NEW);
        this.router.navigate(['/finalizar-pagamento']);
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isMobile = (event.target as Window).innerWidth < 768;
  }

  carouselResponse(plano: CarouselItem): void {
    this.memberService.setMemberId(this.memberId);
    this.planService.setPlanSelected(plano);
    if (this.selectPlan) {
      this.paymentDataService.setOperationType(OperationType.NEW);
    }


    this.router.navigate([`/finalizar-pagamento`]);
  }

  private getAllPlansUpgradeRenewal(planList: any[]): void {
    let filteredPlans = planList.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.plan === item.plan
      ))
    );
    let counter = filteredPlans.length;

    filteredPlans.forEach((planUpgrade: any) => {
      this.planService.getPlanById(planUpgrade.plan).subscribe((plan: Plan) => {
        this.items.push(
          {
            id: plan.id,
            title: plan.planName,
            enableItems: plan.benefits?.filter((benefit: BenefitsModel) => benefit.isActive).map((benefit: BenefitsModel) => benefit.benefitName) as string[],
            disableItems: plan.benefits?.filter((benefit: BenefitsModel) => !benefit.isActive).map((benefit: BenefitsModel) => benefit.benefitName) as string[],
            periodo: plan.periodicities.map((period: PeriodicitiModel) => period.description).join(', '),
            valor: `${this.currencyPipe.transform(plan.amount)}/mês`,
            src: this.commonService.getImageStr(plan.imgPortalPlan?.id as string),
            highlight: plan.highlightOnPortal,
            buttonOptions: plan.highlightOnPortal ? this.buttonOptionsCard : this.buttonOptionsCardSpecial
          }
        );
        counter--;
        if (counter == 0) {
          this.items = this.items.filter((item, index, self) =>
            index === self.findIndex((t) => (
              t.id === item.id
            ))
          );
          this.items.sort((a, b) => {
            let item1 = parseFloat(a.valor.split('/')[0].replace('R$', '').replace('.', '').replace(',', '.'));
            let item2 = parseFloat(b.valor.split('/')[0].replace('R$', '').replace('.', '').replace(',', '.'));
            return item1 - item2;
          });
          this.showPlans = true;
        }
      });

    });
    this.showPlans = true;
  }

  private getAllPlans(): void {
    this.planService.getPublicPlans().subscribe((plans: Page<Plan>) => {
      plans.content?.forEach((plan: any) => {
        if (plan.isActive) {
          this.items.push({
            id: plan.id,
            title: plan.planName,
            enableItems: plan.benefits?.filter((benefit: BenefitsModel) => benefit.isActive).map((benefit: BenefitsModel) => benefit.benefitName) as string[],
            disableItems: plan.benefits?.filter((benefit: BenefitsModel) => !benefit.isActive).map((benefit: BenefitsModel) => benefit.benefitName) as string[],
            periodo: plan.periodicities.map((period: PeriodicitiModel) => period.description).join(', '),
            valor: `${plan.amount ? this.currencyPipe.transform(plan.amount) + '/mês' : 'Gratuito'}`,
            src: this.commonService.getImageStr(plan.imgPortalPlan?.id as string) || localStorage.getItem('imageLogo') as string,
            highlight: plan.highlightOnPortal,
            buttonOptions: plan.highlightOnPortal ? this.buttonOptionsCard : this.buttonOptionsCardSpecial,
            visible: plan.saleLocations.find((location: any) => location.name === 'Site'),
            position: plan.position
          })
        }
      });
      // Ordenar os planos por posição
      this.items.sort((a: any, b: any) => a.position - b.position);
    });
    this.showPlans = true;

  }



  getPlanbyId(): void {
    if (this.user.memberPlans) {
      if (this.renewPlan) {
        if (this.user?.memberSituation?.id != 'a8c3839b-5fb1-4e99-8869-9e30bfee4f7e') {
          this.planService.getCurrentPlanDetail(this.user.id).subscribe({
            next: (res: MemberPlanDetailModel) => {
              this.planDetail = res;
              this.planPaymentsService.getPlanPaymentsRenewals(this.planDetail.planId).subscribe((planList: PlanPaymentUpgradeModel[]) => {
                this.getAllPlansUpgradeRenewal(planList);
              });
            },
            error: (error: any) => {
              console.log('Error: ', error);
            }
          });
        } else {
          this.planService.getLastExpiredMemberPlan(this.user.id).subscribe({
            next: (res: MemberPlanDetailModel) => {
              this.planDetail = res;
              this.planPaymentsService.getPlanPaymentsRenewals(this.planDetail.planId).subscribe((planList: PlanPaymentUpgradeModel[]) => {
                this.getAllPlansUpgradeRenewal(planList);
              });
            },
            error: (error: any) => {
              console.log('Error: ', error);
            }
          });
        }
      } else {
        this.planService.getCurrentPlanDetail(this.user.id).subscribe({
          next: (res: MemberPlanDetailModel) => {
            this.planDetail = res;
            this.planPaymentsService.getPlanPaymentsUpgrades(this.planDetail.id).subscribe((planList: PlanPaymentUpgradeModel[]) => {
              this.getAllPlansUpgradeRenewal(planList);
            });
          },
          error: (error: any) => {
            console.log('Error: ', error);
          }
        });
      }
    }
  }

  private getPlanConfig(): void {
    this.planService.getPlanConfig().subscribe((config: StyleContentPlan) => {
      const style = config;

      this.setStyles(style);
      this.setBtnStyle(style);
    });
  }

  private setStyles(styles: StyleContentPlan): void {
    this.styles = {
      carouselType: styles.carouselType,
      carouselInterval: styles.carouselInterval,
      '--show-section': styles.showSection ? 'flex' : 'none',
      '--show-subtitle': styles.showSubtitle ? 'block' : 'none',
      '--title-size': this.commonService.getRemFontSize(
        styles.titleSize,
        this.isMobile
      ),
      '--title-color': styles.titleColor,
      '--subtitle-size': this.commonService.getRemFontSize(
        styles.subtitleSize,
        this.isMobile
      ),
      '--subtitle-color': styles.subtitleColor,
      '--background-general-color': styles.backgroundGeneralColor,
      '--background-general-image':
        styles.backgroundGeneralType == 'Imagem'
          ? `url(${this.commonService.getImageStr(
            styles.backgrounGeneralImage?.id
          )})`
          : 'none',

      '--card-default-title-size': this.commonService.getRemFontSize(
        styles.cardDefaultTitleSize,
        this.isMobile
      ),
      '--card-default-title-color': styles.cardDefaultTitleColor,
      '--card-default-description-size': this.commonService.getRemFontSize(
        styles.cardDefaultDescriptionSize,
        this.isMobile
      ),
      '--card-default-description-color': styles.cardDefaultDescriptionColor,
      '--card-default-price-size': this.commonService.getRemFontSize(
        styles.cardDefaultPriceSize,
        this.isMobile
      ),
      '--card-default-price-color': styles.cardDefaultPriceColor,
      '--card-default-background-color': styles.cardDefaultBackgroundColor,
      '--card-default-background-image':
        styles.cardDefaultBackgroundType == 'Imagem'
          ? `url(${this.commonService.getImageStr(
            styles.cardDefaultBackgroundImage?.id
          )})`
          : 'none',

      '--card-special-title-size': this.commonService.getRemFontSize(
        styles.cardSpecialTitleSize,
        this.isMobile
      ),
      '--card-special-title-color': styles.cardSpecialTitleColor,
      '--card-special-description-size': this.commonService.getRemFontSize(
        styles.cardSpecialDescriptionSize,
        this.isMobile
      ),
      '--card-special-description-color': styles.cardSpecialDescriptionColor,
      '--card-special-price-size': this.commonService.getRemFontSize(
        styles.cardSpecialPriceSize,
        this.isMobile
      ),
      '--card-special-price-color': styles.cardSpecialPriceColor,
      '--card-special-background-color': styles.cardSpecialBackgroundColor,
      '--card-special-background-image':
        styles.cardSpecialBackgroundType == 'Imagem'
          ? `url(${this.commonService.getImageStr(
            styles.cardSpecialBackgroundImage?.id
          )})`
          : 'none',
    };
  }

  private setBtnStyle(styles: StyleContentPlan): void {
    this.buttonOptionsCard = {
      primaryColor: styles.cardDefaultButtonColor,
      secondaryColor: styles.cardDefaultButtonColor,
      buttonText: styles.cardDefaultButtonText,
      buttonTextColor: styles.cardDefaultButtonTextColor,
      borderRadius: '25px',
      buttonBorderWidth: styles.cardDefaultButtonHasBorder ? '1px' : 'none',
      buttonBorderColor: styles.cardDefaultButtonBorderColor,
      buttonSize: 'btn btn-md',
      fontSize: 12,
    };

    this.buttonOptionsCardSpecial = {
      primaryColor: styles.cardSpecialButtonColor,
      secondaryColor: styles.cardSpecialButtonColor,
      buttonText: styles.cardSpecialButtonText,
      buttonTextColor: styles.cardSpecialButtonTextColor,
      borderRadius: '25px',
      buttonBorderWidth: styles.cardSpecialButtonHasBorder ? '1px' : 'none',
      buttonBorderColor: styles.cardSpecialButtonBorderColor,
      buttonSize: 'btn btn-md',
      fontSize: 12,
    };
  }

  back(): void {
    this.location.back();
  }
}
